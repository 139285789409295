<template>
  <div class="exchange-wrap">
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="warp">
        <div class="banner">
          <img src="../../assets/images/tansuo.jpg">
        </div>

        <div class="history-list-wrap">
          <div class="left">
            <div class="tab-list">
              <div class="title">
                邻里值明细
              </div>
              <div class="integral-wrap">
                <span>当前邻里值:</span>
                <span class="integral">{{integral}}</span>
              </div>
            </div>
            <div class="history-list" v-if="historyList.length > 0">
              <div class="history-title">
                <div class="time">操作时间</div>
                <div class="cause">变更原因</div>
                <div class="integral">邻里值积分</div>
              </div>
              <div class="history-wrap" v-for="(item,index) in historyList" :key="index">
                <div class="time">{{item.date_added}}</div>
                <div class="cause">{{item.title}}</div>
                <div class="integral">{{item.points}}</div>
              </div>
              

            </div>

            <div class="history-list not" v-else>
              <div class="tip">暂无可历史记录</div>
            </div>

            <el-pagination layout="prev, pager, next" :total="total_exchange" :page-size="per_page" :current-page="page" @current-change="changePage" :hide-on-single-page="true" class="pagination">
            </el-pagination>

          </div>
          <div class="right">
            <div class="ad-wrap">
              <div class="img-wrap">
                <img src="../../assets/images/ad2.jpg">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'

export default {
  data () {
    return {
      integral:0,
      historyList: [],
      page: 1,
      per_page: 20,
      total_exchange: 0,
    }
  },
  components: {
    Header2022,
    Footer2022,
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  watch: {
    page: function (e) {
      if (e) {
        this.getGoodsList();
      }
    }
  },
  created () {
    this.getIntegral();
    this.getGoodsList();
  },
  methods: {
    getIntegral: function () {
      let that = this;

      that.$axios.get(that.ocApi + 'account/reward/total', {})
        .then(res => {
          if (res.data.status == 1) {
              that.integral= res.data.data.reward_total
          }
        })
    },
    getGoodsList: function () {
      let that = this;

      let page = that.page;
      let per_page = that.per_page;

      that.$axios.get(that.ocApi + 'account/me/rewards', {
        params: {
          per_page,
          page
        }
      })
        .then(res => {
          that.total_exchange = res.data.total
          that.historyList = res.data.data
        })
    },
    changePage (page) {
      this.page = page
    }
  },
}
</script>

<style lang="scss" scoped>
.exchange-wrap {
  .el-dropdown-link {
    cursor: pointer;
  }
  .pagination {
    text-align: center;
    padding: 20px 0;
  }

  .content {
    background-color: #ffffff;
    min-height: calc(100vh - 226px);
    .warp {
      width: 1200px;
      margin: auto;
      padding-top: 114px;
      .banner {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .history-list-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        padding-bottom: 60px;
        .left {
          flex: 1;
          .tab-list {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            padding: 4px 0;
            .title {
              color: #434343;
            }
            .integral-wrap {
              color: #999999;
              .integral {
                color: #00d596;
                font-weight: 600;
              }
            }
          }
          .history-list {
            &.not {
              text-align: center;
              .tip {
                padding: 60px 0;
              }
            }
            .history-title,.history-wrap{
              display: flex;
              width: 100%;
              padding: 0 28px 0 20px;
              box-sizing: border-box;
              justify-content: space-between;
            }
            .history-title{
              background-color: #F7F7F7;
              height: 46px;
              line-height: 46px;
              margin-top: 25px;
            }
            .history-wrap{
              height: 60px;
              line-height: 60px;
              border-bottom: 1px solid #CCCCCC;
            }
            .time{
              flex: 4
            }
            .cause,.integral{
              flex: 3
            }
            .integral{
              text-align: right;
            }
          }
        }
        .right {
          width: 320px;
          margin-left: 40px;

          .ad-wrap {
            width: 100%;
            border-radius: 4px;
            padding: 16px 20px 20px 20px;
            box-sizing: border-box;
            background-color: #fff;
            margin-top: 24px;
            .title {
              font-size: 16px;
              color: #000000;
              font-weight: 400;
            }
            .img-wrap {
              width: 100%;
              margin-top: 14px;
              > img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>